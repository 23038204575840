<template>
  <v-form
    ref="form"
    v-model="valid"
    class="rcc-warehouses-form"
    @submit.prevent="onSubmitForm"
  >
    <div class="rcc-warehouses-form__form-body">
      <rcc-text-input
        label="Название"
        width="30%"
        v-model="form.name"
        :rules="[value => isRequiredField({ value, field: 'Название' })]"
      />

      <rcc-text-input
        label="Адрес"
        width="30%"
        v-model="form.address"
        :rules="[value => isRequiredField({ value, field: 'Адрес' })]"
      />

      <rcc-number-input v-model="form.size" width="30%" label="Объем склада" :isShowButtons="false" />

      <rcc-text-input
        label="Координаты (долгота, широта)"
        width="30%"
        v-model="coordinates"
        :rules=[coordinatesValidation]
      />

      <rcc-select label="Ответственный сотрудник" width="30%" :items="usersOptions" v-model="form.manager_id" />

      <rcc-text-input
        label="Контакты"
        width="30%"
        v-model="form.contact"
        :rules=[contactsValidation]
      />

      <rcc-textarea v-model="form.note" label="Примечание" width="30%" />
    </div>

    <rcc-footer-buttons :is-submit="isSubmit" @cancel-click="$router.push({ name: 'warehouses' })"/>
  </v-form>
</template>

<script>
import { getOptions } from '@/utils/get-options'
import WarehousesApi from '@/api/warehouses'
import { getUsersList } from '@/api/users'
import Page from '@/mixins/page'
import Form from '@/mixins/form'

import RccNumberInput from 'Components/controls/rcc-number-input.vue'
import RccFooterButtons from 'Components/layouts/rcc-footer-buttons'
import RccTextInput from 'Components/controls/rcc-text-input'
import RccTextarea from 'Components/controls/rcc-textarea'
import RccSelect from 'Components/controls/rcc-select'

export default {
  components: {
    RccTextInput,
    RccFooterButtons,
    RccSelect,
    RccTextarea,
    RccNumberInput
  },

  mixins: [Page, Form, WarehousesApi],

  data() {
    return {
      usersOptions: [],
      coordinates: '',

      form: {
        name: '',
        address: '',
        manager_id: null,
        contact: '',
        note: '',
        size: null
      }
    }
  },

  computed: {
    getCoords() {
      const [lon, lat] = this.coordinates.split(', ')
      return { lon, lat }
    }
  },

  created() {
    getOptions.bind(this)([{
      method: getUsersList,
      target: 'usersOptions',
      errorMessage: 'Не удалось загрузить данные для менеджеров'
    }])
  },

  methods: {
    contactsValidation(value) {
      return value && value.length <= 200 || 'Текст не может быть длиннее 200 символов'
    },

    coordinatesValidation(value) {
      const regexp = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
      return  regexp.test(value) || 'Неверный формат координат. Введите координаты в формате "долгота, широта"'
    }
  }
}
</script>

<style lang="scss" scoped>
.rcc-warehouses-form {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__form-body {
    flex-grow: 1;
    padding: $base-form-padding;
  }

  &__form-section {
    margin: 10px 0;
  }

  &__time-wrapper {
    display: flex;
    gap: 20px;
  }

  &__time-input {
    flex: none
  }
}
</style>
